/* Reseting CSS */
@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;300;400;500;600;700&family=Poppins:wght@200;300;400;500;700&display=swap');

html {
	box-sizing: border-box;
	height: 100vh;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

img {
	max-width: 100%;
	height: auto;
}
body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	/* font-family: sans-serif, 'Poppins'; */
	height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*End Reset*/
/* Main Contianer CSS */
.containerApp {
	max-width: 1020px;
	margin: 0 auto;
	position: relative;
	min-height: 100vh;
	margin-top: 60px;
}
.contianer {
	margin-bottom: 90px;
}
/* .project-layout{

} */
/* End Main Container CSS */
/* Header CSS */
.header {
	display: flex;
	font-size: 16px;
	font-weight: 500;
	height: 60px;
	position: sticky;
	width: 100%;

	top: 0;
	background-color: #f7f7f7;
	z-index: 1;
}
.header-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	font-size: 16px;
	font-weight: 500;
	width: 1020px;

	margin: 0 auto;
}
.logo {
	font-size: 1.3rem;
	cursor: pointer;
	text-transform: uppercase;
	opacity: 0.7;
}
.profileImg {
	display: none;
}
.logo:hover {
	color: #6493eb;
}
.nav-menu {
	display: flex;
	display: relative;
	padding-top: 15px;
}
.nav-menu ul {
	display: flex;
	justify-content: space-between;
}
.nav-menu li {
	list-style: none;
	margin-right: 25px;
	cursor: pointer;
}
.nav-menu li:hover {
	color: #6493eb;
	border-bottom: 2px solid #6493eb;
	transition: scale(1.2);
}
.nav-menu ul li:last-child {
	margin-right: 0;
}
.linkLogo {
	text-decoration: none;
	color: black;
}
.link {
	text-decoration: none;
	color: black;
}
.active {
	color: #6493eb;
}
.mobile-menu {
	display: none;
}
.close {
	display: none;
}

/* Header CSS */

/* Footer CSS*/

.footer {
	display: flex;
	font-size: 16px;
	font-weight: 500;
	height: 80px;
	justify-content: space-between;
	width: 100%;
	padding-top: 12px;
	padding-bottom: 28px;
	top: 0;
	background-color: #f7f7f7;
	z-index: 1;
	height: 100%;
	gap: 5px;
}
.footer-content {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	font-size: 16px;
	font-weight: 500;
	width: 1020px;
	margin: 0 auto;
	margin-top: 10px;
}
.footer-1 {
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid black;
	padding-top: 10px;
	padding-bottom: 12px;
	line-height: 1.8;
	height: auto;
	font-size: 14px;
}
.copy-right {
	display: flex;
	gap: 3px;
	justify-content: center;
	padding: 0;
	margin: 0;
	color: #808080;
}

.socail-media {
	display: flex;
	flex-direction: column;
}

.socail-media .icons {
	display: flex;
	flex-direction: row;
	font-size: 20px;
	justify-content: space-between;
	gap: 30px;
	cursor: pointer;
}
.socail-media .icons a {
	color: #6493eb;
}
.socail-media .icons .linkedin a:hover {
	color: black;
	transform: scale(1.2);
	font-size: 1.2rem;
}
.socail-media .icons .twitter a:hover {
	color: black;
	transform: scale(1.2);
	font-size: 1.2rem;
}
.socail-media .icons .github a:hover {
	color: black;
	transform: scale(1.2);
	font-size: 1.2rem;
}

.footer-title {
	font-size: 1.1rem;
	padding-bottom: 10px;
	font-weight: 400;
}
.contacts {
	row-gap: 5px;
}

/* End Footer CSS*/

/* Home page */
.bio {
	margin-top: 110px;
	display: flex;
	flex-direction: row;
	justify-content: start;
	gap: 90px;
	margin-bottom: 60px;
	flex-wrap: wrap;
}

.bio-info {
	width: 500px;
	font-size: 16px;
	line-height: 1.7rem;
}
.myImg {
	width: 320px;
	height: 368px;
}
.name {
	font-weight: 550;
	color: black;
}
.location {
	color: #808080;
}
.btnContact {
	/* background-color: transparent; */
	height: 32px;
	cursor: pointer;
	font-weight: 600;
	background-color: #6493eb;
	color: white;
	padding: 5px;
}
.buttonp {
	display: none;
}
.btnContact:hover {
	background-color: #6493eb;
	color: white;
	border: none;
	border-radius: 5px;
}

/* start education section */
.button {
	display: none;
}

.background {
	display: flex;
	margin-top: 110px;
	flex-direction: row;
	border-top: 1px solid rgb(0, 0, 0, 0.3);
	padding-top: 20px;
	justify-content: flex-start;
	font-size: 14px;
	flex-wrap: wrap;
	gap: 80px;
	/* background-color: #6493eb;  */
}

.background-title {
	font-size: 17px;
	font-weight: 400;
	position: relative;
	text-transform: uppercase;
}
.background-text {
	margin-top: 5px;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.7rem;
}
.bgText {
	color: #808080;
}
.underline {
	border: 1px solid #6493eb;
	display: inline-block;
	width: 30px;
}

/* start Project */
.project {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	gap: 5px;
	row-gap: 25px;
}
.projectTechnology {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	gap: 5px;
	row-gap: 25px;
}

.project-container {
	margin-bottom: 90px;
}
.section-title {
	grid-column: span 3;
}
.card-content {
	justify-content: space-between;
	padding-bottom: 12px;
	max-height: 480px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.card-content:hover {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.12);
}
.projectImg {
	width: 100%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.imag-coainter {
	display: flex;

	justify-content: center;
	align-items: center;
	position: relative;
	flex-direction: column;
	object-fit: cover;
}
img {
	object-fit: cover;
	width: 320px;
	height: 230px;
}

.project-profile {
	padding: 10px;
	font-size: 1rem;
	line-height: 1.7rem;
}
.links {
	display: flex;
	min-width: 95%;
	justify-content: space-evenly;
}
.button-text {
	opacity: 0.6;
}

a:link {
	text-decoration: none;
}

a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

a:active {
	text-decoration: none;
}

.project-github,
.project-live {
	background-color: #f7f7f7;
	padding: 4px;
	border-radius: 2px;
}
.project-github:hover,
.project-live:hover {
	background-color: #6493eb;
	color: white;
	border-radius: 2px;
}

#project-title {
	font-size: 1.2rem;
	font-weight: 500;
	position: relative;
	text-transform: uppercase;
	opacity: 0.9;
	padding-bottom: 8px;
	border-bottom: 1px solid rgb(0, 0, 0, 0.3);
}

.contact-title {
	font-size: 1.2rem;
	font-weight: 500;
	position: relative;
	text-transform: uppercase;
	opacity: 0.9;
	padding-bottom: 8px;
}
#projectByTechnology {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	align-items: center;
	font-size: 1.7rem;
}
#technologies {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	align-items: center;
	font-size: 1.9rem;
}
.section-title {
	font-weight: 500;
	font-size: 17px;
	text-transform: uppercase;
	margin-bottom: 12px;
}
.project-title {
	font-size: 1.2rem;
	opacity: 0.8;
	padding-top: 5px;
	padding-bottom: 5px;
}
#title-detials {
	margin-bottom: 12px;
}
.usedTechText {
	margin-right: 5px;
}
/* End Project */

/* Start media Queries */
@media screen and (max-width: 600px) {
	.nav-menu {
		display: none;
	}
	.header-content {
		margin-left: 5px;
		margin-right: 5px;
	}
	.header {
		min-width: 100%;
	}

	.mobile-menu {
		display: block;
		cursor: pointer;
		border: none;
		font-size: 20px;
		z-index: 1;
		background-color: #f7f7f7;
	}

	.logo {
		font-size: 1.1rem;
		cursor: pointer;
		text-transform: uppercase;
		opacity: 0.5;
	}
	.linkLogo {
		display: none;
	}
	.profileImg {
		display: block;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		object-fit: cover;

		image-rendering: auto;
		image-rendering: crisp-edges;
		image-rendering: pixelated;
	}
	.bio {
		justify-content: center;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.bio-info {
		padding: 10px;
	}

	.container {
		margin-top: 0;
	}
	.bio-img {
		margin-top: 0;
	}

	.myImg {
		display: none;
	}

	/* start projects button for mobile */
	.btnMain {
		display: flex;
		gap: 50px;
		justify-content: flex-start;
		margin-top: 26px;
	}
	.filterBy {
		display: none;
	}
	.button,
	.buttonp {
		opacity: 0.8;
	}
	.buttonp {
		display: block;
		padding: 7px 14px;
		border: 1px solid #4f4f4f;
		border-radius: 4px;
		transition: all 0.2s ease-in;
		position: relative;
		overflow: hidden;
		font-size: 19px;
		color: black;
		cursor: pointer;
	}

	.buttonp:before {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%) scaleY(1) scaleX(1.25);
		top: 100%;
		width: 140%;

		/* background-color: rgba(0, 0, 0, 0.05); */
		background-color: #f7f7f7;
		border-radius: 50%;
		display: block;
		transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
		z-index: -1;
	}

	.buttonp:after {
		content: '';
		position: absolute;
		left: 55%;
		transform: translateX(-50%) scaleY(1) scaleX(1.45);
		top: 180%;
		width: 160%;
		height: 190%;
		background-color: #6493eb;
		border-radius: 50%;
		display: block;
		transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
		z-index: -1;
	}

	.buttonp:hover {
		color: #ffffff;
		border: 1px solid #6493eb;
	}

	.buttonp:hover:before {
		top: -35%;
		background-color: #6493eb;
		transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
	}

	.buttonp:hover:after {
		top: -45%;
		background-color: #6493eb;
		transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
	}
	buttonp {
		position: relative;
		display: inline-block;
		cursor: pointer;
		outline: none;
		border: 0;
		vertical-align: middle;
		text-decoration: none;
		background: transparent;
		padding: 0;
		font-size: inherit;
		font-family: inherit;
	}
	/* End footer for mobile */
	/*Start Background for mobile*/
	.background {
		margin-top: 0;
		padding: 20px;
		margin-bottom: 0;
		gap: 13px;
	}
	.education {
		margin: 0;
		padding-bottom: 0;
	}
	.background-title {
		margin-top: 13px;
	}
	/* End Background for mobile*/

	/* Start footer for mobile */
	.footer-content {
		flex-direction: column;
	}
	/* .footer {
		padding: 20px;
		padding-top: 0;
	} */
	.footer {
		height: 60px;
	}
	.icons {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding: 5px;
	}
	.copy-right {
		display: none;
	}
	.Year {
		font-size: 12px;
	}
	#project-title {
		margin-top: 20px;
		margin-bottom: 12px;
	}
	.project-container,
	.contianer {
		padding: 12px;
	}

	.mainCard {
		flex-wrap: wrap;
	}
	.top-section {
		padding: 10px;
	}
}

/* Start footer for tablet */
@media screen and (max-width: 600px) {
	.responsive ul {
		display: flex;
		flex-direction: column;
		row-gap: 15px;
		align-items: center;
		background-color: #f7f7f7;
		margin-top: 250px;
		margin-right: 0 !important;
		margin-left: 0 !important;
		min-width: 100%;
		padding-bottom: 10px;
	}
	#dark .responsive ul {
		background-color: #1b1b1b;
	}
	.responsive ul li {
		list-style: none;
	}
	.responsive ul li:last-child {
		margin-right: 25px;
	}
	.responsive {
		display: block;
		position: absolute;
		width: 100% !important;
	}
	.blog,
	.blog-Details {
		padding: 12px;
	}
	.container {
		margin-top: 0;
		padding-left: 10px;
		padding-right: 10px;
	}
	.logo {
		margin-left: 12px;
	}
	.mobile-menu {
		margin-right: 12px;
	}
	.header,
	.footer {
		width: 100%;
	}

	.footer-title {
		display: none;
	}
	.contact-info {
		margin-bottom: 30px;
	}

	.linkedinIcon,
	.twitterIcon,
	.githubIcon {
		font-size: 25px;
		opacity: 0.8;
	}
	.footer {
		padding-top: 0;
	}
	.bio {
		margin-top: 20px;
		direction: column;
		justify-content: center;
	}
	.section-title {
		padding-top: 40px;
	}
	.header-content {
		margin: 0 !important;
		padding: 0 !important;
	}
	.button {
		padding: 5px 10px !important;
	}
	.buttonp {
		padding: 5px 10px !important;
	}
}
@media screen and (max-width: 1007px) {
	.container {
		margin-top: 120;
	}

	.bio {
		margin-top: 60px;
		justify-content: space-around;
		padding: 10px;
	}
	.header-content {
		margin-left: 20px;
		margin-right: 20px;
	}

	.background {
		padding: 20px;
		margin-bottom: 90px;
	}
	.project-container {
		margin-top: 20px;
	}
	.footer-content {
		margin-left: 20px;
		margin-right: 20px;
	}

	.project-container {
		padding: 20px !important;
	}
	#project-title {
		margin-top: -50px;
	}
}
@media screen and (max-width: 1824px) {
	.contact {
		margin-top: 20px;
		padding: 10px;
	}
}
/* End media Queries for tablets */

/* start get in touch button */
.button {
	display: block;
	padding: 7px 14px;
	border: 1px solid #4f4f4f;
	border-radius: 4px;
	transition: all 0.2s ease-in;
	position: relative;
	overflow: hidden;
	font-size: 19px;
	color: black;
	cursor: pointer;
}

.button:before {
	content: '';
	position: absolute;
	left: 50%;
	transform: translateX(-50%) scaleY(1) scaleX(1.25);
	top: 100%;
	width: 140%;

	/* background-color: rgba(0, 0, 0, 0.05); */
	background-color: #f7f7f7;
	border-radius: 50%;
	display: block;
	transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
	z-index: -1;
}

.button:after {
	content: '';
	position: absolute;
	left: 55%;
	transform: translateX(-50%) scaleY(1) scaleX(1.45);
	top: 180%;
	width: 160%;
	height: 190%;
	background-color: #6493eb;
	border-radius: 50%;
	display: block;
	transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
	z-index: -1;
}

.button:hover {
	color: #ffffff;
	border: 1px solid #6493eb;
}

.button:hover:before {
	top: -35%;
	background-color: #6493eb;
	transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button:hover:after {
	top: -45%;
	background-color: #6493eb;
	transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}
button {
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	border: 0;
	vertical-align: middle;
	text-decoration: none;
	background: transparent;
	padding: 0;
	font-size: inherit;
	font-family: inherit;
}

/* end get in touch */

/* projects button */

button {
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	border: 0;
	vertical-align: middle;
	text-decoration: none;
	background: transparent;
	padding: 0;
	font-size: inherit;
	font-family: inherit;
}

button.learn-more {
	width: 8rem;
	height: auto;
}

button.learn-more .circle {
	transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	position: relative;
	display: block;
	margin: 0;
	width: 1.5rem;
	height: 1.5rem;
	background: #6493eb;

	border-radius: 1.625rem;
}

button.learn-more .circle .icon {
	transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	background: black;
}

button.learn-more .circle .icon.arrow {
	transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	left: 0.425rem;
	width: 0.55rem;
	height: 0.115rem;
	background: none;
}

button.learn-more .circle .icon.arrow::before {
	position: absolute;
	content: '';
	top: -0.29rem;
	right: 0.0625rem;
	width: 0.625rem;
	height: 0.625rem;
	border-top: 0.125rem solid #fff;
	border-right: 0.125rem solid #fff;
	transform: rotate(45deg);
}

button.learn-more .button-text {
	transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0 0 0 1rem;
	font-weight: 400;
	line-height: 1.4;
	text-align: center;
	color: black;
}

button:hover .circle {
	width: 100%;
	color: white;
}

button:hover .circle .icon.arrow {
	background: #fff;
	transform: translate(0.5rem, 0);
}

button:hover .button-text {
	color: #fff;
}
/* contact form */
.error-Email {
	background-color: red;
}
.contact-form {
	flex: 1 1 20em;
}
.form-container {
	flex: 2 2 20em;
}
.form-elements {
	display: flex;
	flex-direction: row;
	margin-top: 30px;
	flex-wrap: wrap;
	justify-content: stretch;
}
input[type='text'],
select,
textarea {
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
	width: 100%;
}
input[type='email'] {
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
	width: 100%;
}

input[type='submit'] {
	display: inline-block;
	padding: 7px 14px;
	border: 1px solid #4f4f4f;
	border-radius: 4px;
	transition: all 0.2s ease-in;
	position: relative;
	overflow: hidden;
	font-size: 19px;
	color: black;
	cursor: pointer;
	z-index: 1;
	background-color: #f7f7f7;
}

input[type='submit']:hover {
	background-color: #6493eb;
	color: white;
}

.form-container {
	border-radius: 5px;
	border: 1px solid rgb(0, 0, 0, 0.3);
	padding: 20px;
}
#message {
	height: 200px;
}

.contact-info a {
	text-decoration: none;
	color: black;
}
.contact-info {
	display: flex;
	flex-direction: column;
	color: #6493eb;
	justify-content: space-between;
	cursor: pointer;
	row-gap: 25px;
}
/* contact from */

#success-msg {
	color: #270;
	background-color: #6493eb;
	margin: 10px 0;
	padding: 10px;
	border-radius: 3px 3px 3px 3px;
}
#warning-msg {
	color: #9f6000;
	background-color: #feefb3;
	margin: 10px 0;
	padding: 10px;
	border-radius: 3px 3px 3px 3px;
}
.locationIcon,
.phoneIcon,
.emailIcon {
	margin-right: 20px;
	font-size: 21px;
	padding-bottom: 0;
}

/* start project details */
.project-features {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	gap: 10px;
}

.mainCard {
	display: flex;
	margin-bottom: 20px;
	gap: 10px;
}
.top-section {
	margin-bottom: 30px;
}
.section-title {
	border-bottom: 1px solid black;
}
.backbtn {
	height: 22px;
	width: 30px;
	color: #6493eb;
	display: relative;
}
.hide {
	display: none;
}
.backToProject:hover + .hide {
	display: block;
}

/* end project details */

.btnTchnology {
	margin-right: 5px;
}

.usedTech {
	display: flex;
	opacity: 0.7;
	justify-content: flex-end;
	align-content: center;
	align-items: center;
}

.usedTech .btnTchnology:last-child {
	margin-right: 0;
}
.mainCard,
.description {
	margin-bottom: 17px;
}

#er {
	margin-bottom: 17px;
}
.blog {
	display: grid;
	grid-template-areas: 'a a';
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	gap: 1rem;
	border-bottom: 1px solid rgb(0, 0, 0, 0.3);
	margin-bottom: 28px;
	padding-bottom: 28px;
}
.blog-cotent {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	grid-area: a;
}
/* #projectByTechnology{
		font-family: Lato, sans-serif;
	font-size: 1.9rem;
	line-height: 1.2;
	color: black;
	opacity: 0.9;
	margin-bottom: 14px;
} */

.blog-title {
	font-family: Lato, sans-serif;
	font-size: 1.6rem;
	line-height: 1.2;
	color: black;
	opacity: 0.9;
	margin-bottom: 14px;
}
#dark .blog-title {
	color: white;
}
.blog-body {
	font-size: 1rem;
}

#dark {
	background-color: #1b1b1b;
	color: white !important;
}
#dark .header {
	background-color: #1b1b1b;
	border-bottom: 1px solid white;
}
#dark .linkLogo,
li {
	color: white;
}

#dark .footer {
	background-color: #1b1b1b;
	color: white;
	border-top: 1px solid white;
}

#dark .card-content {
	border: 1px solid white;
	background-color: #343434;
}

#dark .blog {
	border: 1px solid white;
	background-color: #343434;
	padding: 12px;
}

#dark .link {
	color: white;
}
.lightMode {
	font-size: 18px;
	margin-bottom: 5px;
}
#dark .lightMode {
	color: white;
}
#dark span {
	color: white;
}
#dark #project-title {
	border-bottom: 1px solid white;
	margin-bottom: 4px;
}
#dark button {
	background-color: #343434;
	color: white;
}
#dark .project-github {
	background-color: rgba(27, 27, 27, 0.5);
	color: white;
}
#dark .project-live {
	background-color: rgba(27, 27, 27, 0.5);
	color: white;
}

#dar .circle:hover {
	background-color: #6493eb;
}
#dark .project-github:hover {
	color: #6493eb;
}
#dark .project-live:hover {
	color: #6493eb;
}

#dark #ligthIcon {
	background-color: #1b1b1b;
}

#dark .background {
	border-top: 1px solid white;
}
#dark .emailText {
	color: white;
}
#dark .phoneText {
	color: white;
}
#dark .mobile-menu {
	background-color: #1b1b1b;
}
#dark .githubIcon:hover {
	color: white;
}
#dark .twitterIcon:hover {
	color: white;
}
#dark .linkedinIcon:hover {
	color: white;
}

#dark .techCategory {
	color: white;
}

#dark .features {
	background-color: #343434;
}

#dark .form-container {
	background-color: #343434;
	border: 1px solid white;
}
img[alt='Information'] {
	width: 100%;
	height: 100%;
	padding: 10px;
}
img[alt='integration'] {
	width: 100%;
	height: 100%;
	padding: 10px;
}
img[alt='Afghanistan'] {
	width: 100%;
	height: 100%;
	padding: 10px;
}

img[alt='mvc'] {
	width: 70%;
	height: 70%;
	padding: 10px;
}
